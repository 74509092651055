import "./About.css"
import credio from "../../Assets/crediopay.png"
import { motion } from "framer-motion";
const About = () => {
    return ( 
        <div className="about" id="about">
            <div className="feature-head">
                <p>About <span>Credio Pay</span></p>
            </div>
            <motion.div 
                initial={{ y: -100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ type: "spring", duration: 1, delay: 0.3 }}
                className="about-body"
            >
                <p className="about-text">
                    Our payment gateway is a game-changer for businesses looking to optimize their financial operations. It's designed to simplify transactions and enhance security, ensuring a seamless experience for both you and your customers. With robust encryption and fraud prevention measures in place, you can trust that every transaction is secure. Plus, our platform is scalable, meaning it can grow with your business. Say hello to effortless transactions and goodbye to worries about payment security with our cutting-edge payment gateway.
                </p>
                <motion.div 
                    initial={{ y: 100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ type: "spring", duration: 1, delay: 0.5 }}
                    className="credio-images"
                >
                    <img src={credio}></img>
                </motion.div>
            </motion.div>
        </div>
    );
}
 
export default About;