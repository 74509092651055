import "./Navbar.css"
import hambuger from "../../Assets/menu.svg"
import { FaTimes } from "react-icons/fa";
import { useState } from "react";
const Navbar = () => {
    const [show, setShow] = useState(false)
    const handleSide = ()=>{
        setShow(!show);
    }
    return ( 
        <div className="navbar">
            <div className="navbar-logo">
                <p>Credio Pay</p>
            </div>
            <div className="navbar-nav">
                <nav>
                    <a href="#features"><li>Features</li></a>
                    <a href="#about"><li>About</li></a>
                    <a href="#contact"><li>Contact</li></a>
                </nav>
            </div>
            <div className="navbar-button">
                <a href="https://app.sandbox.crediopay.com/"><button className="login-button">Login</button></a>
                <a href="https://app.sandbox.crediopay.com/signup"><button className="register-button">Register</button></a>
            </div>
            <div className="navbar-hamburger" onClick={handleSide}>
                <img src={hambuger}></img>
            </div>
            <div className={show ? `navbar-mobile navbar-mobile-open`: `navbar-mobile`}>
                <div className="nav-close">
                    <FaTimes onClick={handleSide}/>
                </div>
                <nav>
                <a href="#features" onClick={handleSide}><li>Features</li></a>
                    <a href="#about" onClick={handleSide}><li>About</li></a>
                    <a href="#contact" onClick={handleSide}><li>Contact</li></a>
                </nav>
                <div className="navbar-mobile-button">
                    <a href="https://app.sandbox.crediopay.com/"><button className="login-mobile-button">Login</button></a>
                    <a href="https://app.sandbox.crediopay.com/signup"><button className="register-mobile-button">Register</button></a>
                </div>
            </div>
        </div>
    );
}
 
export default Navbar;