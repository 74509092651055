import { Link } from 'react-router-dom';
import logo from '../../Assets/logo.png';
import './Footer.css';
import { motion } from "framer-motion";
const Footer = () => {
    return ( 
        <section className="footer">
            <div className="footer-body">
                <motion.div 
                    initial={{ x: -100, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ type: "spring", duration: 1, delay: 0.3 }}
                    className="footer-body-left"
                >
                    <img src={logo}></img>
                    <p className="what-we-do">Payment built for business owners</p>
                    <p className="location">220 Oyemekun road, beside Michelin Tyres Services, Airways Akure.</p>
                    <p className="lettalk">Let’s Talk:  0805 588 7077</p>
                </motion.div>
                <div className="footer-body-right">
                    <motion.div 
                        initial={{ x: -50, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ type: "spring", duration: 1, delay: 0.5 }}
                        className="company"
                    >
                        <h4 className="footer-head">Company</h4>
                        <a href='#about'><p className="footer-body-2">About Us</p></a>
                        <a href='#contact'><p className="footer-body-2">Contact Us</p></a>
                        <a href="https://crediometer.com/policy" target="_blank"><p className="footer-body-2">Privacy Policy</p></a>
                        <p className="footer-body-2">Terms of Service</p>
                        <a href="https://crediometer.com/faqs" target="_blank"><p className="footer-body-2">FAQS</p></a>
                    </motion.div>
                    <motion.div 
                        initial={{ x: -50, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ type: "spring", duration: 1, delay: 0.8 }}
                        className="company"
                    >
                        <h4 className="footer-head">Socials</h4>
                        <a href='https://www.facebook.com/thegeniuscentre?mibextid=ZbWKwL' target="_blank"><p className="footer-body-2">Facebook</p></a>
                        <a href='https://www.linkedin.com/company/crediometer/' target="_blank"><p className="footer-body-2">LinkedIn</p></a>
                        <a href='https://instagram.com/crediometer?igshid=YmMyMTA2M2Y=' target="_blank"><p className="footer-body-2">Instagram </p></a>
                    </motion.div>
                    <motion.div 
                    initial={{ x: -50, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ type: "spring", duration: 1, delay: 1}}
                    className="company">
                        <h4 className="footer-head">Products</h4>
                        <a href='https://crediometer.com/buy' target="_blank"><p className="footer-body-2">Credio reader</p></a>
                        <p className="footer-body-2">Business tool</p>
                    </motion.div>
                </div>
            </div>
            <p className="credio-footer">Crediometer is a fintech, not a bank. Deposit account services provided by SafeHaven MicroFinance Bank.</p>
        </section>
    );
}
 
export default Footer;