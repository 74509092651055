import "./Business.css"
import { FaCreditCard } from "react-icons/fa6";
import { BiSlider } from "react-icons/bi";
import school from "../../Assets/admin.png"
import reader from "../../Assets/reader.png"
import { motion } from "framer-motion";
const Business = () => {
    return ( 
        <div className="business">
            <div className="feature-head">
                <p>Business <span>Tool</span></p>
            </div>
            <div className="business-body">
                <div className="tool">
                    <div className="tool-details">
                        <motion.h3
                            initial={{ y: -100, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{ type: "spring", duration: 1, delay: 0.5}}
                            className="tool-head"
                        >
                            Customisable Integration:
                        </motion.h3>
                        <motion.p 
                            initial={{ y: -90, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{ type: "spring", duration: 1, delay: 0.8}}
                            className="tool-text"
                        >
                            Effortlessly incorporate our payment gateway into your current platform or website, seamlessly blending it with your existing infrastructure. Our solution offers versatile options that can be customized to meet the unique requirements of your business, ensuring a smooth and tailored payment experience for your customers.
                        </motion.p>
                        <div className="tools-benefit">
                            <motion.div
                                initial={{ x: -100, opacity: 0 }}
                                whileInView={{ x: 0, opacity: 1 }}
                                transition={{ type: "spring", duration: 1, delay: 1}}
                                className="tools-icon"
                            >
                                <FaCreditCard/>
                            </motion.div>
                            <motion.div 
                                initial={{ x: -50, opacity: 0 }}
                                whileInView={{ x: 0, opacity: 1 }}
                                transition={{ type: "spring", duration: 1, delay: 1.2}}
                                className="tools-benefit-text"
                            >
                                <h3>Payment Options:</h3>
                                <p>Explore various payment methods available for transactions.</p>
                            </motion.div>
                        </div>
                        <div className="tools-benefit">
                            <motion.div
                                initial={{ x: -100, opacity: 0 }}
                                whileInView={{ x: 0, opacity: 1 }}
                                transition={{ type: "spring", duration: 1, delay: 1}}
                                className="tools-icon"
                            >
                                <BiSlider/>
                            </motion.div>
                            <motion.div 
                                initial={{ x: -50, opacity: 0 }}
                                whileInView={{ x: 0, opacity: 1 }}
                                transition={{ type: "spring", duration: 1, delay: 1.2}}
                                className="tools-benefit-text"
                            >
                               <h3>Account Settings:</h3>
                                <p>Manage and customize your account preferences and details.</p>
                            </motion.div>
                        </div>
                    </div>
                    <div className="tools-image">
                        <motion.img
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ type: "spring", duration: 1, delay: 1.2}} 
                            src={school}
                        >

                        </motion.img>
                        <motion.div
                            initial={{ x: -50, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }} 
                            transition={{ type: "spring", duration: 1, delay: 1.4}}
                            className="card-1"
                        >
                            <h3>Select a continent payment method</h3>
                            <p>Opt for a continent-specific payment method tailored for your child's school fees to ensure smooth transactions..</p>
                        </motion.div>
                        <motion.div
                            initial={{ x: 50, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }} 
                            transition={{ type: "spring", duration: 1, delay: 1.4}}
                            className="card-2"
                        >
                            <h3>Select a continent payment method</h3>
                            <p>Opt for a continent-specific payment method tailored for your child's school fees to ensure smooth transactions..</p>
                        </motion.div>
                    </div>
                </div>
                <div className="tool tool-2">
                    <div className="tool-details">
                        <motion.h3
                            initial={{ y: -100, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{ type: "spring", duration: 1, delay: 0.5}}
                            className="tool-head"
                        >
                            Boost your sale with credio POS
                        </motion.h3>
                        <motion.p 
                            initial={{ y: -90, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{ type: "spring", duration: 1, delay: 0.8}}
                            className="tool-text"
                        >
                            Boost your sales with Credio POS and experience exponential growth as you streamline transactions, enhance your flow, and empower your business to reach new heights.
                        </motion.p>
                    </div>
                    <div className="tools-image tools-image-2">
                    <motion.img
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ type: "spring", duration: 1, delay: 1.2}} 
                            src={reader}
                        >

                        </motion.img>
                        <motion.div
                            initial={{ x: -50, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }} 
                            transition={{ type: "spring", duration: 1, delay: 1.4}}
                            className="card-3"
                        >
                            <h3>Boost your sale with credio POS</h3>
                            <p>Elevate Sales with Our Credio Reader POS System! Streamline your Transactions, Delight your Customers, and Boost Revenue Today.</p>
                        </motion.div>
                        <motion.div
                            initial={{ x: 50, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }} 
                            transition={{ type: "spring", duration: 1, delay: 1.4}}
                            className="card-4"
                        >
                            <h3>Boost your sale with credio POS</h3>
                            <p>Elevate Sales with Our Credio Reader POS System! Streamline your Transactions, Delight your Customers, and Boost Revenue Today.</p>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Business;