import "./Feature.css";
import inventory from "../../Assets/inventory.png"
import admin from '../../Assets/admin.png'
import credio from '../../Assets/credio.png'
import crediobottom from '../../Assets/credio-bottom.png'
import { motion } from "framer-motion";
const Feature = () => {
    return ( 
        <div className="feature" id="features">
            <div className="feature-head">
                <p>We Provide software features<br></br> that drive <span>substantial impact</span></p>
            </div>
            <div className="feature-body">
                <div className="body-first">
                    <motion.div 
                        initial={{ x: -100, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ type: "spring", duration: 1, delay: 0.3 }}
                        className="mobile-friendly"
                    >
                        <div className="inventory-image">
                            <img src={inventory}></img>
                        </div>
                        <div className="mobile-text">
                            <h3>Mobile-Friendly:</h3>
                            <p>Accessible across various devices, our solution is optimised for mobile compatibility, ensuring a smooth payment experience for your customers on smartphones and tablets.</p>
                        </div>
                    </motion.div>
                    <motion.div 
                         initial={{ x: 100, opacity: 0 }}
                         whileInView={{ x: 0, opacity: 1 }}
                         transition={{ type: "spring", duration: 1, delay: 0.5}}
                        className="side-image-con"
                    >
                        <img src={credio}></img>
                    </motion.div>
                </div>
                <div className="body-first body-second">
                    <motion.div 
                        initial={{ x: -100, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ type: "spring", duration: 1, delay: 0.8}}
                        className="side-image-con side-image-con-second"
                    >
                        <img src={crediobottom}></img>
                    </motion.div>
                    <motion.div 
                        initial={{ x: 100, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ type: "spring", duration: 1, delay: 1.0}}
                        className="mobile-friendly"
                    >
                        <div className="inventory-image admin-image">
                            <img src={admin}></img>
                        </div>
                        <div className="mobile-text">
                            <h3>Customisable Integration: </h3>
                            <p>Seamlessly integrate our payment gateway into your existing platform or website, with flexible options to suit your specific business needs.</p>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
}
 
export default Feature;
