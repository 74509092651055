import "./Header.css"
import mac from "../../Assets/mac.png"
import { motion } from "framer-motion";
const Header = () => {
    return ( 
        <div className="header">
            <div className="header-content">
                <motion.h4 
                    initial={{ y: -100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ type: "spring", duration: 1, delay: 0.3 }}
                    className="header-empower"
                >
                    Empower Your
                </motion.h4>
                <motion.h4 
                    initial={{ y: -50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ type: "spring", duration: 1, delay: 0.5}}
                    className="header-business"
                >
                   Business With Our Seamless Payment Gateway Solution
                </motion.h4>
                <motion.p
                 initial={{ y: -20, opacity: 0 }}
                 whileInView={{ y: 0, opacity: 1 }}
                 transition={{ type: "spring", duration: 1, delay: 0.8}}
                >
                    Elevate your business transactions with our <span>cutting-edge payment gateway,</span> designed to streamline your <span>financial operations</span> effortlessly, Discover the power of our secure payment gateway.
                </motion.p>
                <div className="header-button">
                    <a href="https://app.sandbox.crediopay.com/" target="_blank"> 
                        <motion.button
                            initial={{ x: -100, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ type: "spring", duration: 1, delay: 1}}
                        >
                            Get Started Now
                        </motion.button>
                    </a>
                    <motion.p
                     initial={{ x: -50, opacity: 0 }}
                     whileInView={{ x: 0, opacity: 1 }}
                     transition={{ type: "spring", duration: 1, delay: 1.4}}
                    >
                        See How it Works
                    </motion.p>
                </div>
            </div>
            <div className="header-image">
                <motion.img
                 initial={{ opacity: 0 }}
                 whileInView={{ opacity: 1 }}
                 transition={{ type: "spring", duration: 1, delay: 1.4}}
                 src={mac}
                ></motion.img>
            </div>
        </div>
    );
}
export default Header;
